import { useEffect } from "react";
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import Account from "components/Account";
import Chains from "components/Chains";
import TokenPrice from "components/TokenPrice";
import NFTBalance from "components/NFTBalance";
import { Menu, Layout } from "antd";
import "antd/dist/antd.css";
import "./style.css";
import Text from "antd/lib/typography/Text";
const { Header, Footer } = Layout;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    marginTop: "130px",
    padding: "10px",
    backgroundRepeat: 'no-repeat',

  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
    backgroundImage: `url("https://lucrar.pt/wp-content/uploads/2019/08/stock2.png")`,
    backgroundSize: "full",
    backgroundRepeat: "no-repeat",
    paddingLeft: "100px",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
    paddingRight: "100px",
  },
};
const App = ({ isServerInfo }) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  return (
    <Layout style={{ height: "100vh", overflow: "auto" }}>
      <Router>
        <Header style={styles.header}>
          <Logo />
          <Menu
            
            mode="horizontal"
            style={{
              display: "flex",
              fontSize: "17px",
              fontWeight: "500",
              width: "100%",
              justifyContent: "center",
              background: "transparent",
              border:"0",
              
            }}
            defaultSelectedKeys={["home"]}
          >
            <Menu.Item key="home">
              <a href="https://lucrar.pt">Home</a>
              <Redirect to="/nonauthenticated" />
            </Menu.Item>
            <Menu.Item key="investir">
              <a href="https://lucrar.pt/lcr">LCR</a>
            </Menu.Item>
            <Menu.Item key="nft">
              <NavLink to="/nftBalance">NFT</NavLink>
            </Menu.Item>
            <Menu.Item key="cursos">
              <a href="https://lucrar.pt/cursos">Cursos</a>
            </Menu.Item>
            <Menu.Item key="servicos">
              <a href="https://lucrar.pt/servicos">Serviços</a>
            </Menu.Item>
            <Menu.Item key="novidades">
              <a href="https://lucrar.pt/novidades">Novidades</a>
            </Menu.Item>
            <Menu.Item key="equipa">
              <a href="https://lucrar.pt/equipa">Equipa</a>
            </Menu.Item>
          </Menu>
          <div style={styles.headerRight}>
            <Chains />
            <TokenPrice
              address="0x1510211E6DC81F5724A1BecA33C5AC70Dcca6CE0"
              chain="bsc"
              image="https://952456.smushcdn.com/1439925/wp-content/uploads/2022/01/COIN.png?lossy=1&strip=1&webp=1"
              size="28px"
            />
            <Account />
          </div>
        </Header>
        <div style={styles.content}>
          <Switch>
            <Route path="/nftBalance">
              <NFTBalance />
            </Route>
            <Route path="/nonauthenticated">
              <>Por favor, autentique a sua carteira metamask e depois aceda à sua&nbsp;</><NavLink to="/nftBalance">coleção</NavLink>
              
            </Route>
          </Switch>
          {/*<Redirect to="/nonauthenticated" />*/}

        </div>
      </Router>
      <Footer style={{ textAlign: "center" }}>
      

        <Text style={{ display: "block" }}>
        Copyright © {" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://lucrar.pt"
            
          >
            Lucrar.pt
          </a> <br></br>Todos os direitos reservados.
        </Text>
      </Footer>
    </Layout>
  );
};

export const Logo = () => (
  <div style={{ display: "flex" }}>
    <img src="https://lucrar.pt/wp-content/uploads/2022/03/lucrar50px-4.png" alt=""/>
  </div>
);

export default App;
